<template>
  <div>
    <b-modal
      ref="modal"
      :id="'modal-edit-aoi-' + cardData.aoi_id"
      title="Edit AOI"
      size="lg"
      ok-title="Submit"
      no-close-on-backdrop
      no-close-on-esc
      @ok="handleEditOk"
      :ok-disabled="this.editeDialogOKDisabled"
    >
      <form ref="form">
        <b-form-group
          label="Name"
          label-for="aoi-name-input"
          class="label-bold"
        >
          <b-form-input
            id="aoi-name-input"
            v-model="editDialog.aoiName"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Description"
          label-for="aoi-description-input"
          class="label-bold"
        >
          <b-form-textarea
            id="aoi-description-input"
            placeholder="AOI description ..."
            v-model="editDialog.aoiDescription"
            rows="3"
            no-resize
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="input-group-aoi-coordinates-1"
          label="Coordinates (South-West)"
          class="label-bold mt-2"
        >
          <div class="row">
            <div class="col">
              <b-form-group
                valid-feedback="Latitude value valid"
                invalid-feedback="Latitude value invalid"
                :state="validateBoundingBoxInputs('bbSWLat')"
                class="font-weight-validation"
              >
                <b-form-input
                  v-model="editDialog.bbSWLat"
                  placeholder="Latitude ..."
                  size="sm"
                  autocomplete="off"
                  type="number"
                  step="0.1"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group
                valid-feedback="Longitude value valid"
                invalid-feedback="Longitude value invalid"
                :state="validateBoundingBoxInputs('bbSWLon')"
                class="font-weight-validation"
              >
                <b-form-input
                  v-model="editDialog.bbSWLon"
                  placeholder="Longitude ..."
                  size="sm"
                  autocomplete="off"
                  type="number"
                  step="0.1"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-form-group>

        <b-form-group
          id="input-group-aoi-coordinates-2"
          label="Coordinates (North-East)"
          class="label-bold mb-2"
        >
          <div class="row">
            <div class="col">
              <b-form-group
                valid-feedback="Latitude value valid"
                invalid-feedback="Latitude value invalid"
                :state="validateBoundingBoxInputs('bbNELat')"
                class="font-weight-validation"
              >
                <b-form-input
                  v-model="editDialog.bbNELat"
                  placeholder="Latitude ..."
                  autocomplete="off"
                  size="sm"
                  type="number"
                  step="0.1"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group
                valid-feedback="Longitude value valid"
                invalid-feedback="Longitude value invalid"
                :state="validateBoundingBoxInputs('bbNELon')"
                class="font-weight-validation"
              >
                <b-form-input
                  v-model="editDialog.bbNELon"
                  placeholder="Longitude ..."
                  autocomplete="off"
                  size="sm"
                  type="number"
                  step="0.1"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-form-group>
      </form>
    </b-modal>

    <!-- <b-card :id="cardID" @click="cardClicked(cardID)" v-bind:class="{ cardActive: isCardActive }" class="mb-2"> -->
    <b-overlay :show="deleteAOIOverlay == true" rounded="sm">
      <b-card
        :id="cardID"
        @click="cardClicked(cardID)"
        v-bind:class="{ cardActive: this.cardID == this.selectedAOIItem }"
        class="mb-2"
      >
        <b-card-title>
          <div class="row pb-0">
            <div class="col col-7 my-auto">
              <span class="">{{ cardData.aoi_name }}</span>
            </div>
            <div class="col col-5 text-right">
              <b-button
                v-b-tooltip.hover
                title="Edit"
                @click="editAOIClicked(cardData.aoi_id)"
                size="sm"
                variant="secondary"
                class="mb-0 mr-1"
                data-cy="submit-edit"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="Copy"
                @click="copyAOIClicked(cardData.aoi_id)"
                size="sm"
                variant="secondary"
                class="mb-0 mr-1"
              >
                <b-icon icon="files"></b-icon>
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="Delete"
                @click="deleteAOIClicked(cardData.aoi_id)"
                size="sm"
                variant="secondary"
                class="mb-0 mr-1"
                data-cy="submit-delete"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </b-card-title>
        <b-card-text class="card-text">
          <span>{{ cardData.description }}</span>
          <br />
          <span v-if="cardData.region != null"
            >Region: {{ cardData.region }}</span
          >
        </b-card-text>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import axios_services from "@/axios/axios-services.js";
import L from "leaflet";

export default {
  name: "AOIItem",
  components: {},
  props: {
    cardData: { type: Object, required: true },
    cardID: { type: String },
    cardCurrent: { type: String },
  },
  data() {
    return {
      deleteAOIOverlay: false,
      editDialog: {
        aoiName: "",
        aoiDescription: "",
        bbSWLat: "",
        bbSWLon: "",
        bbNELat: "",
        bbNELon: "",
      },
      latLongValidation: {
        bbSWLat: false,
        bbSWLon: false,
        bbNELat: false,
        bbNELon: false,
      },
    };
  },
  computed: {
    selectedAOIItem() {
      return this.$store.getters.getAOIsActiveItem;
    },
    editeDialogOKDisabled() {
      if (
        this.editDialog.aoiName.length == 0 ||
        this.editDialog.aoiDescription.length == 0 ||
        this.aoiCoordinatesValid == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    aoiCoordinatesValid() {
      if (
        this.latLongValidation["bbSWLat"] == true &&
        this.latLongValidation["bbSWLon"] == true &&
        this.latLongValidation["bbNELat"] == true &&
        this.latLongValidation["bbNELon"] == true
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    /***
     * Card clicked event handler
     */
    cardClicked(id) {
      if (id == this.cardCurrent) {
        eventBus.$emit("updateLocationAOIItem");
      } else {
        this.$store.commit("setAOIsActiveItem", id);
        this.$emit("notification_aoiItemDeleted");
      }
    },
    /***
     * Event handler for the delete AOI button
     */
    deleteAOIClicked(aoiID) {
      this.showDeleteItemModal(aoiID);
    },
    /**
     * Event handler for the copy AOI button
     */
    copyAOIClicked() {
      this.showNotification(
        "secondary",
        "AOI copied",
        "AOI copied to clipboard",
        2000
      );
      var bounds = L.geoJson(this.cardData.geoJSON).getBounds();
      this.$store.commit("setClipboardCoordinates", bounds);
    },
    /***
     * Event handler for the edit AOI button
     */
    editAOIClicked(aoiID) {
      this.$root.$emit("bv::show::modal", "modal-edit-aoi-" + aoiID);
      this.editDialog.aoiName = this.cardData.aoi_name;
      this.editDialog.aoiDescription = this.cardData.description;
      var bounds = L.geoJson(this.cardData.geoJSON).getBounds();
      this.editDialog.bbSWLat = bounds["_southWest"]["lat"];
      this.editDialog.bbSWLon = bounds["_southWest"]["lng"];
      this.editDialog.bbNELat = bounds["_northEast"]["lat"];
      this.editDialog.bbNELon = bounds["_northEast"]["lng"];
    },
    /**
     * Modal dialog to confirm the delete operation
     */
    showDeleteItemModal(aoiID) {
      this.$parent.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete the AOI.", {
          title: "Please Confirm",
          id: "deleteAOI",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.deleteAOIOverlay = true;
            axios_services
              .delete("aoi/delete/id/".concat(aoiID))
              .then(() => {
                this.deleteAOIOverlay = false;
                this.showNotification(
                  "success",
                  "Success! AOI deleted",
                  "AOI successfully deleted"
                );
                this.$store.dispatch("getAOIs").then(() => {
                  this.$emit("notification_AOICreated", aoiID);
                });
                this.$store.commit("setAOIsActiveItem", null);
                this.$store.dispatch("loadMaxNumberAoisReached");
              })
              .catch((error) => {
                this.deleteAOIOverlay = false;
                var errorMsg = error.toString();
                console.log("Error: AOI could not be deleted: ", errorMsg);
                this.showNotification(
                  "danger",
                  "Error!",
                  "AOI could not be deleted"
                );
              });
          }
        });
    },
    /**
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content, hideDelay = 4000) {
      this.$parent.$bvToast.toast(content, {
        autoHideDelay: hideDelay,
        title: title,
        variant: variant,
        solid: true,
      });
    },
    /***
     * Eventhandler for the edit dialog submit button
     */
    handleEditOk() {
      this.deleteAOIOverlay = true;

      var bounds = [
        [this.editDialog["bbSWLat"], this.editDialog["bbSWLon"]],
        [this.editDialog["bbNELat"], this.editDialog["bbNELon"]],
      ];
      var editedGeometry = L.rectangle(bounds).toGeoJSON().geometry;

      var payload = {
        aoi_id: this.cardData.aoi_id,
        aoi_name: this.editDialog.aoiName,
        description: this.editDialog.aoiDescription,
        user_id: this.cardData.customer_id,
        geoJSON: editedGeometry,
      };

      axios_services
        .post("/aoi/update/filter", payload)
        .then(() => {
          this.$store.commit("setAOIsActiveItem", null);
          this.deleteAOIOverlay = false;
          this.showNotification(
            "success",
            "Success! AOI updated",
            "AOI successfully updated"
          );
          this.$store.dispatch("getAOIs").then(() => {
            this.$store.commit("setAOIsActiveItem", this.cardID);
          });
        })
        .catch((error) => {
          this.deleteAOIOverlay = false;
          var errorMsg = error.response.data.message.error_definition.message;
          console.log("Error: AOI could not be updated: ", errorMsg);
          this.showNotification("danger", "Error!", "AOI could not be updated");
        });
    },
    /***
     * This functions scrolls to the selected card item
     */
    scroll(id) {
      var selector = "#" + String(id);
      const el = this.$el.querySelector(selector);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    /**
     * This function validates if the submitted bounding box is valid
     */
    validateBoundingBoxInputs(coordinate) {
      if (this.editDialog[coordinate] == "") {
        this.latLongValidation[coordinate] = false;
        return null;
      } else {
        var validationVal = this.validateLatLongCoordinates(
          coordinate,
          this.editDialog[coordinate]
        );
        this.latLongValidation[coordinate] = validationVal;
        return validationVal;
      }
    },
    /***
     * This function checks if a aoi coordinate is valid
     */
    validateLatLongCoordinates(latLonType, latLonValue) {
      if (latLonType == "bbSWLat" || latLonType == "bbNELat") {
        if (latLonValue >= -90 && latLonValue <= 90) {
          return true;
        } else {
          return false;
        }
      } else if (latLonType == "bbSWLon" || latLonType == "bbNELon") {
        if (latLonValue >= -180 && latLonValue <= 180) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  mounted() {},
  beforeDestroy() {},
  created() {},
  watch: {
    //  /***
    //   * This watch method recognizes clicks on the card items (when the cardID changes)
    //   */
    //   cardCurrent: {
    //     handler: function (val) {
    //       if (val == this.cardID) {
    //         this.scroll(this.cardID);
    //       } else {
    //         this.productBoxOpened = false;
    //       }
    //     },
    //     deep: true,
    //   },
  },
};
</script>

<style scoped>
.card-title {
  font-size: 1em;
  color: #007bff;
  font-weight: 600;
}

.card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-text {
  color: #191919;
  /*font-style: italic;*/
  font-size: 0.85em;
}

.card:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.cardActive {
  background-color: #f0f0f0;
}

.label-bold {
  font-weight: bold;
}

.font-weight-validation {
  font-weight: normal;
}
</style>
